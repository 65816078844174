import * as React from "react"
import Layout from "../components/layout"
import WideImage from "../components/WideImage"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo";
import Navbar from "../components/navbar";

const img_height = 240;

const TeamPage = () => (

  <Layout className="page team-page">
    <Seo title={"Team"}/>

    <h1 className="mb-4">Meet Our Team</h1>


    <div className="row col-10 offset-1 mb-4">
      <p>
        Our team includes engineering, research and communications professionals with significant experience in the development and commercialisation of data collection systems, as well industry leading knowledge on residential energy and water usage.
      </p>
    </div>

    <div className="row bio">
      <div className="col-2 offset-1 text-end">
        <StaticImage src="../images/richard-profile-600.jpeg" height={img_height} imgClassName={'img-fluid rounded'} alt="Richard Johnson"/>
      </div>
      <div className="col-8">
        <h3>Richard Johnson - Chief Executive Officer (Director)</h3>
        <p>
          Richard is an experienced managing director with extensive experience in the IT industry. He holds bachelor and master’s degrees in engineering and an MBA. Richard has a proven track
          record of product development and commercialisation in senior management roles which includes 12 years as the Managing Director of DTI Group Ltd which had an annualised revenue growth rate
          of 24% during that period coupled with a successful IPO on the ASX in 2014.
        </p>
        <a href={'https://www.linkedin.com/in/richard-johnson-53732419/'}><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16"><path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/></svg></a>
      </div>
    </div>

    <div className="row bio">
      <div className="col-2 offset-1 text-end">
        <StaticImage src="../images/gary-profile-600.jpeg" height={img_height} imgClassName={'img-fluid rounded'} alt="Richard Johnson"/>
      </div>
      <div className="col-8">
        <h3>Gary McGhee - Technical Lead (Director)</h3>
        <p>
          Gary is a Software Engineer with over 25 years’ experience working with innovative companies across a broad range of industries; from early digital video to Perth's Scitech exhibition
          workshop to safety-critical software for eye surgery and train systems, to web, mobile and IoT applications including Josh's House. Gary has a BAppSc degree in Computer Technology
          (software and hardware) from Curtin University. Gary is particularly inspired by challenges in intuitive product design for consumers, systems architecture, and enabling consumers to
          reduce their environmental impact.
        </p>
        <a href={'https://www.linkedin.com/in/garymcghee'}><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16"><path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/></svg></a>
      </div>
    </div>


    <div className="row bio">
      <div className="col-2 offset-1 text-end">
        <StaticImage src="../images/josh-profile-600.jpeg" height={img_height} imgClassName={'img-fluid rounded'} alt="Richard Johnson"/>
      </div>
      <div className="col-8">
        <h3>Josh Byrne - Research and Communications Lead (Director)</h3>
        <p>
          Josh is an environmental scientist with a national profile as a consultant, researcher and communicator in urban sustainability. He holds a PhD in environmental engineering and has led
          national research projects in high performance housing and water sensitive urban design. Josh’s family home near Fremantle, WA is an exemplar of energy and water efficient design and acts
          as a test bed for the development Huey Co technology.
        </p>
        <a href={'https://www.linkedin.com/in/josh-byrne-65b6175b'}><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16"><path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/></svg></a>
      </div>
    </div>


    <div className="row bio">
      <div className="col-2 offset-1 text-end">
        <StaticImage src="../images/jean-profile-600.jpeg" height={img_height} imgClassName={'img-fluid rounded'} alt="Richard Johnson"/>
      </div>
      <div className="col-8">
        <h3>Jean Ferreira - Product Development Engineer</h3>
        <p>
          Jean is an innovator with degrees in mechatronics engineering and business administration. He has the ability to design from the circuit board all the way up to the software and has been
          involved in the prototyping and commercialisation of a range of industrial environmental monitoring devices and data management platforms.
        </p>
        <a href={'https://www.linkedin.com/in/jean-ferreira-567171b'}><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16"><path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/></svg></a>
      </div>
    </div>


    <div className="row bio">
      <div className="col-2 offset-1 text-end">
        <StaticImage src="../images/aaron-profile-600.jpeg" height={img_height} imgClassName={'img-fluid rounded'} alt="Richard Johnson"/>
      </div>
      <div className="col-8">
        <h3>Aaron Locke - Product Development Engineer</h3>
        <p>
          Aaron is mechanical engineer with a background in product design, development and project management. His experience has been honed from working across defence, energy, mining and
          communication sectors, resulting in an efficient and robust approach to working with technology, as well as product prototyping and deployment.
        </p>
        <a href={'https://www.linkedin.com/in/aaron-locke-12578521'}><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16"><path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/></svg></a>
      </div>
    </div>

  </Layout>
)

export default TeamPage
